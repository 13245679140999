<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout, PageHeader
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "CloudKitch",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="card" style="margin-top:-30px">
        <div class="card-body">
            <div class="row">
                <h2 class="text-center">
                    <a href="">Welcome to Cloudkitch</a>
                </h2>
            </div>
        </div>
    </div>
  </Layout>
</template>


 